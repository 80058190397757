import "./technologies.css";
import Nodes from "../../assets/technology-background.svg";
import TechView from "../../Components/TechView/TechView";
import FrontEndSvg from "../../assets/SVG/Front-End-svg";
import BackendSvg from "../../assets/SVG/Back-End-svg";
import MobileSvg from "../../assets/SVG/Mobile-svg";
import DatabaseSvg from "../../assets/SVG/Database-svg";
import CloudSvg from "../../assets/SVG/Cloud-svg";
import CmsSvg from "../../assets/SVG/Cms-svg";
import { useNavigate } from "react-router";
import MarketingSvg from "../../assets/SVG/Marketing-svg";

const Technologies = () => {
  const navigate = useNavigate();

  const scrollToDiv = () => {
    const element = document.getElementById("technologies");
    if (element) {
      const offset = 100; // Offset value in pixels
      const elementPosition =
        element.getBoundingClientRect().top + window.pageYOffset; // Element's position relative to the document
      const offsetPosition = elementPosition - offset; // Calculate the position with offset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="technologies-main-container">
      <div className="technologies-header-container">
        <p className="main-service-header">
          Infinitude<span className="quote-mark">,</span>
          <br />
          <span className="quote-mark">“</span>Where Innovation Meets the Right
          Technology<span className="quote-mark">”</span>
        </p>
        <div className="divider"></div>
        <span className="technology-description">
          We push the boundaries of possibility with the latest and most
          advanced technologies, crafting visionary solutions that redefine
          innovation. Partner with us to transform your ideas into cutting-edge,
          custom-built software that drives your success and shapes the future.
        </span>
        <div className="helper-button-container">
          <button className="service-button" onClick={scrollToDiv}>
            LEARN MORE
            <svg
              className="arrow-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path d="M8 12.293l4.146-4.146a.5.5 0 0 0-.708-.708L8 11.793 4.562 7.44a.5.5 0 1 0-.708.708L8 12.293z" />
            </svg>
          </button>
          <button
            className="service-button"
            onClick={() => navigate("/ContactUs")}
          >
            CONTACT US
          </button>
        </div>
      </div>
      <div className="technology-container">
        <div className="technology" id="technologies">
          <p className="main-service-header">Frontend Development</p>
          <div className="technology-divider"></div>
          <div className="technology-list">
            {[
              <TechView icon={<FrontEndSvg type="react" />} title="React" />,
              <TechView
                icon={<FrontEndSvg type="Angular" />}
                title="Angular"
              />,
              <TechView icon={<FrontEndSvg type="vue" />} title="Vue" />,
              <TechView icon={<FrontEndSvg type="html" />} title="HTML" />,
            ].map((tech) => tech)}
          </div>
        </div>
        <div className="technology">
          <p className="main-service-header">Backend Development</p>
          <div className="technology-divider"></div>
          <div className="technology-list">
            {[
              <TechView icon={<BackendSvg type="Node" />} title="NodeJs" />,
              <TechView icon={<BackendSvg type="Python" />} title="Python" />,
              <TechView icon={<BackendSvg type="Java" />} title="Java" />,
              <TechView
                icon={<BackendSvg type="Spring" />}
                title="Spring Boot"
              />,
              <TechView icon={<BackendSvg type="PHP" />} title="PHP" />,
            ].map((tech) => tech)}
          </div>
        </div>
        <div className="technology">
          <p className="main-service-header">Mobile App Development</p>
          <div className="technology-divider"></div>
          <div className="technology-list">
            {[
              <TechView icon={<MobileSvg type="Apple" />} title="iOS" />,
              <TechView icon={<MobileSvg type="Android" />} title="Android" />,
              <TechView
                icon={<MobileSvg type="react" />}
                title="React Native"
              />,
              <TechView icon={<MobileSvg type="Flutter" />} title="Flutter" />,
            ].map((tech) => tech)}
          </div>
        </div>
        <div className="technology">
          <p className="main-service-header">Databases</p>
          <div className="technology-divider"></div>
          <div className="technology-list">
            {[
              <TechView icon={<DatabaseSvg type="SQL" />} title="My SQL" />,
              <TechView icon={<DatabaseSvg type="Mongo" />} title="MongoDB" />,
              <TechView icon={<DatabaseSvg type="pg" />} title="Postgres" />,
              <TechView
                icon={<DatabaseSvg type="firebase" />}
                title="Firebase"
              />,
            ].map((tech) => tech)}
          </div>
        </div>
        <div className="technology">
          <p className="main-service-header">DevOps & Cloud</p>
          <div className="technology-divider"></div>
          <div className="technology-list">
            {[
              <TechView icon={<CloudSvg type="aws" />} title="AWS" />,
              <TechView icon={<CloudSvg type="gcp" />} title="Google Cloud" />,
              <TechView
                icon={<CloudSvg type="azure" />}
                title="Microsoft Azure"
              />,
              <TechView icon={<CloudSvg type="fastlane" />} title="Fastlane" />,
              <TechView icon={<CloudSvg type="ci" />} title="CI-CD" />,
            ].map((tech) => tech)}
          </div>
        </div>
        <div className="technology">
          <p className="main-service-header">Project Management</p>
          <div className="technology-divider"></div>
          <div className="technology-list">
            {[
              <TechView icon={<CmsSvg type="jira" />} title="Jira" />,
              <TechView
                icon={<CmsSvg type="confluence" />}
                title="Confluence"
              />,
              <TechView icon={<CmsSvg type="slack" />} title="Slack" />,
              <TechView icon={<CmsSvg type="teams" />} title="Teams" />,
            ].map((tech) => tech)}
          </div>
        </div>
        <div className="technology">
          <p className="main-service-header">CMS</p>
          <div className="technology-divider"></div>
          <div className="technology-list">
            {[
              <TechView icon={<CmsSvg type="shopify" />} title="Shopify" />,
            ].map((tech) => tech)}
          </div>
        </div>
        <div className="technology">
          <p className="main-service-header">Digital Marketing Tools</p>
          <div className="technology-divider"></div>
          <div className="technology-list">
            {[
              <TechView icon={<MarketingSvg type="meta" />} title="Meta Ads" />,
              <TechView
                icon={<MarketingSvg type="google" />}
                title="Google Ads"
              />,
              <TechView
                icon={<MarketingSvg type="hubspot" />}
                title="Hubspot"
              />,
              <TechView
                icon={<MarketingSvg type="hootsuite" />}
                title="Hootsuite"
              />,
              <TechView
                icon={<MarketingSvg type="salesforce" />}
                title="Salesforce"
              />,
            ].map((tech) => tech)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Technologies;
