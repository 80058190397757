import "./offer.css";
import { useEffect, useRef, useState } from "react";
import ResearchDevelopment from "../../../assets/research-development.jpg";
import CloudSolution from "../../../assets/cloud-solution.jpg";
import QATesting from "../../../assets/qa-testing.jpg";
import Marketing from "../../../assets/Marketing-consulting.png";
import Support from "../../../assets/support.jpg";
import "intersection-observer";

const Offer = () => {
  const [isVisible, setIsVisible] = useState(false);

  const missionContainerRef = useRef<any>(null);

  useEffect(() => {
    const handleScroll = () => {
      const missionContainer = missionContainerRef.current;
      if (!missionContainer) {
        return;
      }

      const rect = missionContainer.getBoundingClientRect();

      const isCurrentlyVisible =
        rect.top < window.innerHeight && rect.bottom >= 10;
      setIsVisible(isCurrentlyVisible);
    };

    const handleFocus = () => {
      setIsVisible(true);
    };

    // Initial check
    handleScroll();

    // Attach scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Attach focus event listener
    const missionContainer = missionContainerRef.current;
    if (missionContainer) {
      missionContainer.addEventListener("focus", handleFocus);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
      if (missionContainer) {
        missionContainer.removeEventListener("focus", handleFocus);
      }
    };
  }, []);

  return (
    <div className="offer-main-container">
      <p className="main-service-header">What We Offer</p>
      <div className="offer-container">
        <div className="offer-card">
          <img src={ResearchDevelopment} alt="Research and Development" />
          <h3>Research & Development</h3>
          <p>
            Our highly-skilled development team conducts a comprehensive
            technical analysis to understand your unique software, web, and
            mobile application needs. We then identify the optimal combination
            of technologies and expertise to ensure your project is staffed with
            the right talent, delivering robust solutions for your specific
            goals.
          </p>
        </div>
        <div className="offer-card">
          <img src={CloudSolution} alt="Cloud Solution" />
          <h3>Cloud Solutions</h3>
          <p>
            Our cloud experts thoroughly assess your unique infrastructure
            needs, selecting the best cloud technologies to match your business
            objectives. We deliver tailored, secure, and scalable cloud
            solutions that optimize performance and efficiency. From migration
            to ongoing management, we ensure your cloud environment is always
            aligned with your growth.
          </p>
        </div>
        <div className="offer-card">
          <img src={QATesting} alt="QA and Testing" />
          <h3>QA & Testing</h3>
          <p>
            Our QA & Testing team rigorously evaluates every aspect of your
            software to ensure quality, performance, and reliability. By
            combining automated and manual testing, we identify potential issues
            early, reducing risk and enhancing the user experience. We
            collaborate with your development team to deliver high-performing,
            bug-free applications that meet your business goals.
          </p>
        </div>
        <div className="offer-card">
          <img src={Marketing} alt="Digital Marketing Consulting" />
          <h3>Digital Marketing </h3>
          <p>
            we specialize in delivering tailored digital marketing solutions
            that help your business thrive in the online world. From SEO & PPC
            advertising to social media management and content creation, our
            team is dedicated to driving measurable results. We take the time to
            understand your unique goals, crafting strategies that increase
            brand visibility, engage your audience, & drive conversions.
          </p>
        </div>
        <div className="offer-card">
          <img src={Support} alt="Support & Maintenance" />
          <h3>Support & Maintenance</h3>
          <p>
            Our Support & Maintenance team ensures your software continues to
            perform optimally post-launch. We provide timely assistance and
            proactive updates to address issues, improve functionality, and
            adapt to evolving needs. By working closely with your team, we
            ensure your applications remain reliable, secure, and aligned with
            your business objectives.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Offer;
